import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import TestimonialWithImageYari from "components/ui/extended/TestimonialWithImageYari";
import AIBenefits from "components/ui/extended/AIBenefits";
import PlatformFeatures from "components/ui/extended/PlatformFeatures";
import PlainHeaderWithPartner from "components/ui/base/PlainHeaderWithPartner";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{ withCta: false, withMenu: false }}
    >
      <SEO
        title={t("pages.customer-service-solution.seo.title")}
        description={t("pages.customer-service-solution.seo.description")}
        image={{
          relativePath: "meta/customer-service-software.jpg",
          alt: "Customer support Software to make your customer life easier",
        }}
      />

      <PlainHeaderWithPartner
        title={t("pages.index.title")}
        titleMaxWidth={1060}
        descriptionMaxWidth={900}
        description={t("pages.index.description")}
        ctaRegister={{ show: true }}
        partner={{
          name: "Revolut",
          logoRelativePath: "partners/Revolut.jpg",
        }}
        image={{
          relativePath: "pages/index/AI-customer-service-solution.jpg",
          alt: "Customer support Chatbot with AI",
        }}
      />

      <Customers />

      <AIBenefits />

      <PlatformFeatures location={location} />

      <RegisterForCustomerService />

      <TestimonialWithImageYari />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
